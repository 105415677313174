<template>
  <div
    :class="[
      `app-badge app-badge--${type}`,
      { '!rounded-full': rounded  },
    ]"
     :style="type === 'custom' ? { '--custom-bg-color': backgroundColor, '--custom-text-color': textColor } : {}"
  >
    {{ $t(label as string) }}
    <button
      v-if="closable"
      @click="close"
      type="button"
      :class="
        type === 'primary'
          ? `hover:bg-primaryG-50/20`
          : `hover:bg-${type}-100/20`
      "
      class="group relative -mr-1 size-3.5 rounded-sm"
    >
      <span class="sr-only">Remove</span>
      <span
        :class="
          type === 'primary'
            ? `stroke-primaryG-50/75 group-hover:stroke-primaryG-100/95`
            : type === 'custom'
            ? `stroke-[var(--custom-text-color)]`
            : `stroke-${type}-50/50 group-hover:stroke-${type}-100/75`
        "
        class="size-3.5"
        v-html="$svg.tailwindClose"
      ></span>
      <span class="absolute -inset-1" />
    </button>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    label?: string
    rounded?: boolean
    type?: string // primary, secondary, custom
    closable?: boolean
    backgroundColor?: string // only works if type is custom
    textColor?: string // only works if type is custom
  }>(),
  {
    type: 'primary',
  }
)

const emit = defineEmits(['close'])

function close() {
  emit('close')
}
</script>
<style scoped>
.app-badge--custom {
  background-color: var(--custom-bg-color);
  color: var(--custom-text-color);
}

</style>
